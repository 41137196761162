import React from "react";
import { NavigationButton } from "../Button";
import { NavigationItem } from "../types";

export const Item: React.FC<{
  item: NavigationItem;
}> =
  // eslint-disable-next-line react/display-name
  React.forwardRef<
    HTMLButtonElement,
    {
      item: NavigationItem;
    }
  >(({ item }, ref) => {
    return (
      <NavigationButton
        key={item.link}
        refObject={ref}
        item={item}
        dropdown={true}
      />
    );
  });
