import { IconVariants } from "_components/Icon/types";

export const externalLinks = [
  { label: "About", link: "https://www.fidaapp.io" },
  { label: "Docs", link: "https://docs.fidaapp.io" },
  {
    label: "Discord",
    link: "https://discord.com/invite/DSEUdXSr5K",
  },
  { label: "Contact", link: "https://www.fidaapp.io/#contact" },
];

export const socialLinks: {
  label: string;
  link: string;
  icon: IconVariants;
}[] = [
  {
    label: "Discord",
    link: "https://discord.com/invite/DSEUdXSr5K",
    icon: "discord",
  },
  { label: "GitHub", link: "https://github.com/Fidaapp", icon: "github" },
  { label: "Twitter", link: "https://twitter.com/fidaapp", icon: "twitter" },
];
