import { shortenString } from "utils/address";
import { useAccount } from "wagmi";
import { Button } from "../Button";

interface WalletProps {
  disconnect: () => void;
  walletAddress?: string;
}

const Wallet: React.FC<WalletProps> = ({ disconnect, walletAddress }) => {
  const { connector } = useAccount();

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <p className="text-primary-700">
        Connected with <strong>{connector?.name}</strong>
      </p>
      <span className="flex h-[44px] items-center justify-center rounded-3xl border-2 border-tertiary-100 px-6 font-bold text-primary-700">
        {shortenString(walletAddress)}
      </span>
      <Button variant="primary-dark" onClick={() => disconnect()}>
        Disconnect
      </Button>
    </div>
  );
};

export default Wallet;
