import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import { Spinner } from "../Spinner";

type Variants =
  | "primary-dark"
  | "primary-light"
  | "secondary"
  | "tertiary"
  | "disabled";

type ButtonProps = {
  rootClass?: string;
  variant: Variants;
  isLoading?: boolean;
  fullWidth?: boolean;
  contentWidth?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variantStyle: { [key in Variants]: string } = {
  "primary-light":
    "bg-secondary-600 hover:bg-secondary-400 text-primary-700 shadow-[0_2px_4px_rgba(44,72,147,0.1)]",
  "primary-dark":
    "bg-primary-600 hover:bg-primary-400 text-white shadow-[0_2px_4px_rgba(44,72,147,0.1)]",
  secondary:
    "bg-white border-2 border-tertiary-100 text-primary-700 hover:text-tertiary-200 shadow-[0_2px_4px_rgba(44,72,147,0.1)]",
  tertiary:
    "bg-white border border-primary-600 text-tertiary-200 shadow-[0px_0px_0px_2.5px_white] shadow-primary-100",
  disabled:
    "bg-functional-100 text-functional-300 cursor-not-allowed shadow-[0_2px_4px_rgba(44,72,147,0.1)]",
};

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    variant,
    rootClass,
    isLoading = false,
    fullWidth = false,
    contentWidth = false,
    disabled,
    ...rest
  } = props;

  return (
    <button
      disabled={disabled}
      className={clsx(
        "fle flex h-[44px] items-center justify-center whitespace-nowrap rounded-3xl px-6 font-medium",
        rootClass,
        fullWidth && "w-full",
        !contentWidth && "min-w-[216px]",
        isLoading ? variantStyle.disabled : variantStyle[variant],
        disabled && variantStyle.disabled
      )}
      {...rest}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};
