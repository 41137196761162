import { Connector, useConnect } from "wagmi";
import DialogWindow from "../Dialog";
import WalletIcon from "./walletIcon";

type WalletsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletsDialog: React.FC<WalletsDialogProps> = ({ isOpen, onClose }) => {
  const { connect, connectors } = useConnect();

  const connectWallet = (connector: Connector) => {
    connect({ connector });
    onClose();
  };

  return (
    <DialogWindow
      title="Select a wallet"
      isOpen={isOpen}
      onClose={() => onClose()}
    >
      <div className="flex flex-col gap-4">
        {connectors?.map((connector) => (
          <button
            key={connector.id}
            className="flex items-center justify-start gap-4 p-2 border border-tertiary-100 bg-white rounded-lg cursor-pointer font-medium hover:bg-tertiary-100"
            onClick={() => connectWallet(connector)}
          >
            <WalletIcon walletID={connector.id} />
            {connector.name}
          </button>
        ))}
      </div>
    </DialogWindow>
  );
};

export default WalletsDialog;
