import { WalletButton } from "components/Button";
import React, { useState } from "react";
import { useGlobalContext } from "src/_contexts/global/hooks";
import { useDisconnect } from "wagmi";
import { Button } from "../Button";
import DialogWindow from "../Dialog";
import Logo from "../Logo";
import { CompactNavigation, Navigation } from "../Navigation";
import Wallet from "../Wallet";
import WalletsDialog from "../WalletsDialog";

export const NavBar: React.FC = () => {
  const { address, openWallet, setOpenWallet } = useGlobalContext();
  const [openWalletDetails, setOpenWalletDetails] = useState(false);
  const { disconnect } = useDisconnect();

  const disconnectWallet = () => {
    disconnect();
    setOpenWalletDetails(false);
  };

  // TODO: move WalletsDialog & DialogWindow to the Layout component?
  return (
    <nav className="z-[3]">
      <WalletsDialog isOpen={openWallet} onClose={() => setOpenWallet(false)} />
      <DialogWindow
        isOpen={openWalletDetails}
        onClose={() => setOpenWalletDetails(false)}
      >
        <Wallet walletAddress={address} disconnect={disconnectWallet} />
      </DialogWindow>
      <div className="grid grid-cols-[auto_auto] items-center justify-between gap-4 p-4 md:grid-cols-[1fr_2fr_1fr] md:p-8">
        <Logo />
        <div className="hidden justify-center md:flex">
          <Navigation className="hidden lg:inline-flex" />
          <CompactNavigation className="absolute left-1/2 top-7 -translate-x-1/2 lg:hidden" />
        </div>
        <div className="flex justify-end">
          {address && (
            <WalletButton onClick={() => setOpenWalletDetails(true)} />
          )}
          {!address && (
            <Button
              variant="primary-light"
              contentWidth
              onClick={() => setOpenWallet(true)}
            >
              Connect wallet
            </Button>
          )}
        </div>
      </div>
      <div className="flex justify-center p-4 md:hidden lg:p-8">
        <CompactNavigation />
      </div>
    </nav>
  );
};
