import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { Icon } from "_components/Icon";
import { DialogWindowProps } from "./interfaces";

const DialogWindow: React.FC<DialogWindowProps> = ({
  children,
  isOpen,
  onClose,
  title,
  titleBackground,
}) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 z-[4] overflow-auto bg-black/40"
            aria-hidden="true"
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 z-[4] flex items-center justify-center overflow-y-auto py-2 px-2 md:items-start md:py-24">
            <Dialog.Panel className="min-h-[248px] min-w-[328px] rounded-2xl bg-white">
              <Dialog.Title
                className={clsx(
                  "flex items-center justify-between rounded-t-lg pl-4 pt-4 pr-4 pb-1 text-lg font-medium text-primary-700",
                  titleBackground && "bg-tertiary-100"
                )}
              >
                <span>{title}</span>
                <button onClick={() => onClose()}>
                  <div className="rounded-full bg-tertiary-100 p-1">
                    <Icon icon="close" aria-label="Close window" />
                  </div>
                </button>
              </Dialog.Title>
              <Dialog.Description className="p-4" as="div">
                {children}
              </Dialog.Description>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default DialogWindow;
