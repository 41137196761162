import WalletIcon from "components/WalletsDialog/walletIcon";
import { shortenString } from "utils/address";
import { useAccount } from "wagmi";
import { Button } from "./Base";

export const WalletButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { address, connector } = useAccount();

  return (
    <Button
      variant="secondary"
      rootClass="gap-2"
      onClick={() => onClick()}
      contentWidth
    >
      <WalletIcon walletID={connector?.id} rootClass="w-6 h-6" />
      {shortenString(address)}
    </Button>
  );
};
