import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useRouter } from "next/router";
import React, { Fragment, useMemo } from "react";
import { Icon } from "_components/Icon";
import { NavigationButton } from "../Button";
import { navigationItems } from "../items";
import { NavigationProps } from "../types";
import { Item } from "./item";

const CompactNavigation: React.FC<NavigationProps> = ({
  className,
  items = navigationItems,
}) => {
  const router = useRouter();

  const visibleItem = useMemo(() => {
    return items.find((item) => router.pathname.includes(item.link));
  }, [items, router.pathname]);

  return (
    <div
      className={clsx(
        "inline-flex gap-2 rounded-[40px] bg-primary-700 p-1",
        className
      )}
    >
      {visibleItem && <NavigationButton item={visibleItem} />}
      <Menu as="div" className="relative">
        <div className="flex h-full flex-row items-center justify-center">
          <Menu.Button className="flex cursor-pointer flex-row items-center justify-center border-0 bg-transparent text-white">
            <Icon icon="more" className="w-[20px] text-primary-600" />
            <Icon icon="expand" className="mr-2" />
          </Menu.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Menu.Items className="absolute -right-2 z-[3] mt-4 flex min-w-[200px] flex-col gap-2 rounded-2xl border border-primary-500 bg-primary-700 p-4 shadow-[0_4px_12px_4px_rgba(0,0,0,0.5)]">
            {items
              .filter((item) => item !== visibleItem)
              .map((item) => (
                <Menu.Item key={item.link}>
                  <Item item={item} />
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default CompactNavigation;
