import { Icon } from "_components/Icon";

const icons: Record<
  string,
  "metamask" | "venly" | "wallet-connect" | "coinbase"
> = {
  metaMask: "metamask",
  venly: "venly",
  walletConnect: "wallet-connect",
  coinbaseWallet: "coinbase",
};

const WalletIcon: React.FC<{ walletID?: string; rootClass?: string }> = ({
  walletID,
  rootClass = "h-8 w-8",
}) => {
  return walletID ? (
    <Icon icon={icons[walletID]} className={rootClass} />
  ) : null;
};

export default WalletIcon;
