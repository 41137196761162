import { Icon } from "_components/Icon";
import Logo from "../Logo";
import { externalLinks, socialLinks } from "./items";

export const Footer: React.FC = () => {
  return (
    <footer className="z-[2] flex min-h-[10rem] flex-col justify-between gap-14 bg-primary-700 p-4 md:flex-row md:p-8 lg:gap-8">
      <Logo />

      <div className="flex flex-col gap-4 md:flex-row md:gap-14">
        {externalLinks.map((externalLink) => (
          <a
            key={externalLink.label}
            href={externalLink.link}
            className="text-sm tracking-[.02em] text-white no-underline"
            rel="noreferrer"
            target="_blank"
          >
            {externalLink.label}
          </a>
        ))}
      </div>

      <div className="flex flex-row gap-6">
        {socialLinks.map((item) => (
          <a
            key={item.label}
            href={item.link}
            rel="noreferrer"
            target="_blank"
            title={item.label}
            className="h-6 w-auto text-white"
          >
            <Icon icon={item.icon} />
          </a>
        ))}
      </div>
    </footer>
  );
};
