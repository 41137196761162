import Image from "next/image";
import NextLink from "next/link";
import * as React from "react";

const Logo: React.FC = () => {
  return (
    <NextLink href="/swap" passHref>
      <a>
        <Image
          src="/images/fida-logo-long-white.svg"
          alt="FIDA"
          height={30}
          width={95}
        />
      </a>
    </NextLink>
  );
};

export default Logo;
