import clsx from "clsx";
import { NavigationButton } from "../Button";
import { navigationItems } from "../items";
import { NavigationProps } from "../types";

const Navigation: React.FC<NavigationProps> = ({
  className,
  items = navigationItems,
}) => {
  return (
    <nav
      className={clsx(
        "inline-flex gap-1 rounded-[40px] bg-primary-700 p-1",
        className
      )}
    >
      {items.map((item) => (
        <NavigationButton key={item.link} item={item} />
      ))}
    </nav>
  );
};

export default Navigation;
