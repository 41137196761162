import clsx from "clsx";
import { useRouter } from "next/router";
import { Icon } from "_components/Icon";
import { NavigationItem } from "../types";

type NavigationButtonProps = {
  item: NavigationItem;
  dropdown?: boolean;
  refObject?: React.ForwardedRef<HTMLButtonElement>;
};

export const NavigationButton: React.FC<NavigationButtonProps> = (props) => {
  const { item, dropdown = false, refObject } = props;

  const router = useRouter();

  return (
    <button
      ref={refObject}
      onClick={() => router.push(item.link)}
      className={clsx(
        "fle flex h-[44px] items-center whitespace-nowrap rounded-3xl border-2 border-transparent bg-primary-700 px-6 font-medium text-white hover:border-primary-600",
        dropdown ? "gap-4" : "justify-center gap-2",
        router.pathname.includes(item.link) &&
          "border-primary-600 bg-tertiary-200"
      )}
    >
      <Icon icon={item.icon} className="text-primary-600" /> {item.label}
    </button>
  );
};
